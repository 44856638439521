.directorUsers {
    &--id {
        width: 10%;
    }

    &--title {
        width: 10%;

        &.blue {
            color: var(--blue-link-text);
        }
    }

    &--phone {
        width: 13%;
    }

    &--name {
        color: var(--black-table);
        font-family: proximaSemibold;
    }

    &--delete {
        &-button {
            margin-right: 32px;
            width: fit-content;
            color: var(--red-link-text);
            text-decoration: underline;
            font-size: 18px;
            transition: all .2s ease;
            margin-left: auto;
            margin-top: 5px;
            user-select: none;

            &:hover {
                cursor: pointer;
                color: var(--red-1);
                text-decoration: none;
            }

            &:active {
                color: var(--red-link-text);
            }
        }
    }
}