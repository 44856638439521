@import '../../../../styles/assets/mixins';

.checkEmailHeader {
    @include flexColumn;
    align-items: center;
    font-family: proximaSemibold;
    font-size: 2rem;
    line-height: 1.15;
}

.checkEmailText {
    @extend .checkEmailHeader;
    font-family: proximaRegular;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 1.25rem;
}
