@mixin closeButton($rotate) {
    content: '';
    height: 11px;
    width: .4px;
    background-color: var(--grey-5);
    border: .1px solid var(--grey-5);
    border-radius: 0.375rem;
    transform: rotate($rotate);
    position: absolute;
    left: 8.4px;
    top: 3px;
}

.modal {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0;
    transition: all .2s ease;
    pointer-events: none;

    &.enter-done {
        opacity: 1;
        pointer-events: visible;

        & .modal-content {
            transform: translateY(0);
        }
    }

    &.exit {
        opacity: 0;

        & .modal-content {
            transform: translateY(-200px);
        }
    }

    &-content {
        width: 414px;
        background-color: var(--white);
        transform: translateY(-200px);
        transition: all .2s ease;
        padding: var(--inner-content-padding);
        border-radius: 0.375rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.medium {
            width: 74%;
        }

        &.medium-1 {
            width: 60%;
        }

        &.noPadding {
            padding: 0;
        }

        &--header {

            &--title {
                text-align: center;
                font-family: proximaSemibold;
                font-size: 28px;
            }

            &--close {
                position: absolute;
                top: 0.625rem;
                right: 0.625rem;
                cursor: pointer;
                transition: all .2s ease;
                border: 1px solid var(--grey-2);
                border-radius: 0.375rem;
                height: 20px;
                width: 20px;

                &::after {
                    @include closeButton(45deg);
                }

                &::before {
                    @include closeButton(-45deg);
                }

                &:hover {
                    opacity: .5;
                    transform: rotate(90deg);
                }
            }
        }


        &--footer {
            width: 100%;

            & button {
                &:not(:first-child) {
                    margin-top: 0.625rem;
                }
            }
        }

        &--body {
            width: 100%;
        }
    }
}
