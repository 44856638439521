.refreshIcon {
    margin-right: 30px;
    position: relative;

    &--icon {
        transition: all .5s ease;
        display: flex;

        &:hover {
            opacity: .8;
            cursor: pointer;
            transform: rotate(360deg);
        }
    }

    &--info {
        height: 1rem;
        width: 24px;
        background-color: var(--blue-link-text);
        border-radius: 0.375rem;
        position: absolute;
        top: -8px;
        right: -14px;
        color: var(--white);
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
