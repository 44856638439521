.appointDriverModal {
    &--orderId {
        margin-top: 24px;
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 300;
        color: var(--grey-10);

        &-shortInfo {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
        }

        &-photo {
            height: 64px;
            width: 64px;
            margin-right: 14px;
            background-image: url(../../../img/drivers/driver_appointment.svg);
        }

        &-info {
            &_name {
                font-size: 17px;
                margin-bottom: 4px;
            }

            &_rating {
                margin-left: 0.75rem;
                font-size: 17px;
                color: var(--grey-10);
            }
        }

        &-mainInfo {
            display: flex;

            &_left {
                width: 65%;
            }

            &_right {
                width: 35%;
            }

            &_title {
                font-size: 0.75rem;
                color: var(--grey-3);
            }

            &_item {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
                height: 35px;

                &__defaultInfo {
                    font-size: 18px;
                    font-weight: 400;

                    &.green {
                        color: var(--green-2);
                    }

                    &.yellow {
                        color: var(--yellow-1);
                    }
                }
            }
        }
    }
}

.orderIdPlate {
    font-size: 18px;
    color: var(--white);
    margin-top: 4px;
    padding: 0.3px 0.5rem;
    background-color: var(--green-2);
    border-radius: 2px;
    width: fit-content;
    height: 23px;
    display: flex;
    align-items: center;
}
