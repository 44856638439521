.divider {
    width: 100%;
    height: 1px;
    background-color: var(--grey-2);
    margin-bottom: 2rem;

    &.smallMargin {
        margin-bottom: 1rem;
    }
}
