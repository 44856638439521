.infoCategory {
    display: inline-block;
    font-size: 1rem;
    padding: 5px 0.5rem;
    border-radius: 4px;
    //margin-top: 29px;
    user-select: none;
    cursor: default;

    &.blue {
        background-color: var(--blue-link-background);
        color: var(--blue-link-text);
    }

    &.red {
        background-color: var(--red-link-background);
        color: var(--red-link-text);
    }
}
