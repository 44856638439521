$backround-color: #F0F0F0;

.fileInput-field {
    position: relative;

    &:not(:first-child) {
        margin-left: 30px;
    }

    &--file {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
    }
    &__item {
        display: flex;
        align-items: flex-start;
        gap: 5px;
    }

    & span {
        font-size: 0.75rem;
        color: var(--grey-3);
    }

    &__wrap {
        position: relative;
        width: 120px;
        height: 100px;
    }

    & .change-photo-img {
        display: none;
        pointer-events: none;
    }

    & label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 14px 20px 12px 20px;
        background-color: $backround-color;
        border-radius: 0.375rem;
        border: 1px solid var(--grey-2);
        transition: all .2s ease;
        width: 250px;
        height: 250px;
        margin-top: 0.5rem;

        &.yellow {
            border: 2px solid var(--yellow-1);
        }

        &[image='true'] {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            &:hover {
                +.change-photo-img {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -40%);
                }
            }
        }

        &:not(label[image='true']) {
            background-size: 24px;
            background-image: url(../../../../../img/icons/inputs/upload_file.svg);
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: 40%;

            &::after {
                content: 'Завантажити файл';
                font-size: 0.75rem;
                color: var(--grey-3);
                font-family: proximaSemibold;
                margin-top: 0.75rem;
            }
        }

        &:hover {
            filter: brightness(50%);
            cursor: pointer;
        }
    }
    &.isDriverPage {
        & label {
            width: 120px;
            height: 100px;
            &[image='true'] {
                &:hover {
                    +.change-photo-img {
                        display: flex;
                        gap: 0.625rem;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
        & .zoom {
            &-photo {
                &__btn {
                    margin-top: 0.625rem;
                    padding: 5px;
                    min-width: unset;
                    & svg {
                        width: 20px;
                        fill: var(--blue-4);
                    }
                    &:hover {
                        & svg {
                            fill: var(--white);
                        }
                    }
                }
                &__overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100vh;
                    background-color: rgba(0, 0, 0, .5);
                    z-index: 10;
                }
                &__modal {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &__img {
                    max-width: 800px;
                    width: 90%;
                    z-index: 12;
                }
                &__close {
                    position: absolute;
                    top: -25px;
                    right: -25px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid var(--white);
                    color: var(--white);
                    background-color: var(--black-table);
                    transform: translate(-50%, -50%);
                    cursor: pointer;
                }
            }
        }
    }
}
