@import 'reset';
@import 'assets/variables';
@import 'assets/mixins';
@import 'assets/fonts';
@import 'reset';
@import 'global/form';
@import 'global/table';
@import 'global/global';

:root {

  @media screen and (max-width: 106em) {
    font-size: 15px;
  }

  @media screen and (max-width: 99em) {
    font-size: 13px;
  }

  @media screen and (max-width: 87em) {
    font-size: 11px;
  }
}

* {
    box-sizing: border-box;
}

body {
    font-family: proximaRegular, sans-serif;
    font-size: 1rem;
}

h2 {
    font-size: 2rem;
}

::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
    background-color: var(--grey-2);
}

::-webkit-scrollbar-thumb {
    background-color: var(--grey-3);
    border-radius: 0.375rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.customDate--wrapper {
    z-index: 100;
}

.ant-select-dropdown {
  z-index: 100;
  border-radius: 0.375rem;
  padding: 0;
  border: 1px solid var(--grey-2);
  box-shadow: none;
  margin-top: -0.875rem !important;

  .ant-select-item-empty {
    padding: 11px;
    color: var(--grey-3) !important;
  }

  .ant-select-item {
    background-color: #fff !important;
    padding: 0.6875rem;
    border-radius: 0;

    &:hover {
      background-color: var(--blue-link-background) !important;
    }
  }
  .ant-select-item-option-content {
    font-family: proximaRegular, sans-serif;
    font-weight: normal;
    color: var(--grey-3) !important;
    font-size: 1rem;
  }
}

.rc-virtual-list-scrollbar {
  width: 0.375rem !important;

  .rc-virtual-list-scrollbar-thumb {
    background-color: var(--grey-3) !important;
  }
}
