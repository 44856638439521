.newOrderControls {
    width: 31.25rem;
    margin-top: 0.75rem;
}

.createNewOrder {
  & .divider {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  & .marginBottom {
    margin-bottom: 0.625rem !important;
  }
}

.newOrderWrap{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  .orderAddress{
    width: 100%;
  }
  .additionalServices{
    width: 100%;
  }
}
.newOrderColumn{
  display: flex;
  flex-direction: column;
  gap: 16px;
  input{
    width: 100%;
  }
}
.ColumnOrderInfo{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
