@font-face {
    font-family: proximaRegular;
    src: url(../../fonts/ProximaNova-Regular.woff);
    font-display: block;
}

@font-face {
    font-family: proximaSemibold;
    src: url(../../fonts/ProximaNova-Semibold.woff);
    font-display: block;
}

@font-face {
    font-family: proximaBold;
    src: url(../../fonts/ProximaNova-Bold.woff);
    font-display: block;
}