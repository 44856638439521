.tableWrapper {
    margin-top: 1.125rem;

    @media (max-width: 1441px) {
        font-size: 13px;
    }

    table {
        table-layout: fixed;
    }

    .scrollableBody {
        height: 62vh;
        overflow-y: auto;
        transition: all .2s;

        &.collapsed {
            height: calc((var(--table-row-height) * 2) + 10px);
        }
    }

}

table {
    width: 100%;
    border-collapse: separate;

    td {
        padding-left: 1.25rem;
        border-top: 0.25rem solid var(--blue-1);
        border-bottom: 0.25rem solid var(--blue-1);
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        font-size: 1.125rem;

        @media(max-width: 1480px) {
            font-size: 13px;
        }

        @media(max-width: 1410px) {
            font-size: 0.75rem;
        }

        &:before {
            content: "";
            border-bottom: 1px solid var(--grey-2);
            border-top: 1px solid var(--grey-2);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
        }

        &.search {
            padding-left: 1.375rem;
        }

        &:first-child {
            border-radius: 0.375rem 0 0 0.375rem;

            &:before {
                content: "";
                border-left: 1px solid var(--grey-2);
                position: absolute;
                border-radius: 0.375rem 0 0 0.375rem;
                pointer-events: none;
            }
        }

        &:last-child {
            border-radius: 0 0.375rem 0.375rem 0;

            &:before {
                content: "";
                border-right: 1px solid var(--grey-2);
                position: absolute;
                border-radius: 0 0.375rem 0.375rem 0;
                pointer-events: none;
            }
        }
    }

    th {
        text-align: left;
        padding-left: 1.5rem;
        color: var(--table-header-text) !important;
        font-family: proximaSemibold;

        &>div {
            cursor: default;
        }

        & div.tableHeaderWrapper {
            display: flex;
            align-items: center;

            &.sortable {
                cursor: pointer;
                user-select: none;

                &:hover {
                    opacity: .8;
                }
            }
        }

        span.smallTHText {
            font-size: 0.75rem;
            color: var(--grey-th-small-text);
            height: 1rem;
        }
    }

    tbody {
        tr {
            height: var(--table-row-height);
            background-color: var(--white);
            transition: all .2s ease;

            &.activeRow {
                background-color: var(--yellow-2);

                td {
                    &:before {
                        border-bottom: 1px solid var(--yellow-1);
                        border-top: 1px solid var(--yellow-1);
                    }

                    &:first-child {
                        &:before {
                            border-left: 1px solid var(--yellow-1);
                        }
                    }

                    &:last-child {
                        &:before {
                            border-right: 1px solid var(--yellow-1);
                        }
                    }
                }
            }

            &.redRow {
                background-color: var(--red-row-bg);

                td {
                    &:before {
                        border-bottom: 1px solid var(--red-row-border);
                        border-top: 1px solid var(--red-row-border);
                    }

                    &:first-child {
                        &:before {
                            border-left: 1px solid var(--red-row-border);
                        }
                    }

                    &:last-child {
                        &:before {
                            border-right: 1px solid var(--red-row-border);
                        }
                    }
                }
            }
            &.greenRow {
              background-color: var(--green-row-bg);

              td {
                  &:before {
                      border-bottom: 1px solid var(--green-1);
                      border-top: 1px solid var(--green-1);
                  }

                  &:first-child {
                      &:before {
                          border-left: 1px solid var(--green-1);
                      }
                  }

                  &:last-child {
                      &:before {
                          border-right: 1px solid var(--green-1);
                      }
                  }
              }
          }

            &:hover {
                cursor: pointer;
                background-color: var(--grey-2);
            }
        }
    }

    .tableSearchPanel {
        background-color: var(--white);
        height: 2.75rem;
        border-bottom: 0.25rem solid var(--blue-1);
        border-top: 1rem solid var(--blue-1);

        &.sticky {
            position: sticky;
            top: 0;
        }
    }
}
