.orderComment {

    &--item {
        width: 100%;
        gap: 2rem;
    }
}

.orderAppointment {
    display: flex;
    gap: 1rem;

    .checkbox-container  {
      margin: 0;
      padding: 11px 42px 11px 55px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
}

.innerWrapper {
  height: 84vh;
}
