@import "../../../../styles/assets/mixins";

.select-box {
    @include flexColumn;
    width: 280px;
    position: relative;
    z-index: 10;

    &.highZIndex {
        z-index: 15;
    }

    &.marginRight {
        margin-right: 18px;
    }

    &.fullWidth {
        width: 100%;
    }

    & .options-container {
        max-height: 0px;
        opacity: 0;
        background-color: var(--white);
        color: var(--black);
        width: 100%;
        transition: all .2s ease;
        border: 1px solid var(--grey-2);
        border-radius: 0 0 6px 0.375rem;
        overflow: hidden;
        order: 1;
        position: absolute;
        top: var(--select-option-height);
        left: 0;

        &.active {
            opacity: 1;
            max-height: calc(var(--select-option-height) * 5);

            &+.selected::after {
                transform: rotateX(180deg);
            }

            &.scrollable {
                overflow-y: scroll;
            }
        }

        &::-webkit-scrollbar {
            width: 0.375rem;
            background-color: var(--grey-2);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--grey-3);
            border-radius: 0.375rem;
        }
    }

    & .option {
        height: var(--select-option-height);
        padding: 1rem;
        cursor: pointer;
        transition: all .2s ease;

        &:hover {
            background-color: var(--blue-link-background);
        }

        & .radio {
            display: none;
        }
    }

    & label {
        cursor: pointer;
    }

}

.selected {
    background-color: var(--white);
    color: var(--black);
    padding: 1rem;
    border: 1px solid var(--black);
    border-radius: 0.375rem;
    position: relative;
    transition: all .2s ease;
    order: 0;
    font-family: proximaSemibold;

    &.green {
        color: var(--active-green);
    }

    &.yellow {
        color: var(--yellow-1);
    }

    &.red {
        color: var(--red-link-text);
    }

    &.grey {
        color: var(--grey-3);
    }

    &:not(.disabled) {
        cursor: pointer;

        &::after {
            content: '';
            background: url('../../../../img/icons/inputs/select-arrow-down.svg');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            height: 0.375rem;
            width: 0.75rem;
            right: 0.875rem;
            top: 1.25rem;
            transition: all .5s ease;
        }
    }
}
