@import '../../../styles/assets/mixins';

.authRight {
    width: 50%;
    height: 100vh;
    background-color: var(--blue-1);
}

.loginLogo {
    position: fixed;
    top: 43px;
    left: var(--login-right-margin);
    @include flexColumn;
}

.loginHeader {
    position: fixed;
    bottom: 71%;
    left: var(--login-right-margin);
}

.backButtonWrapper {
    position: fixed;
    bottom: 78%;
    left: var(--login-right-margin);
}