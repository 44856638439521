.activeDrivers {
    &--status {
        width: 12%;

        &-wrapper {
            display: flex;
            flex-direction: column;
        }

        span {
            &.statusText {
                &.green {
                    color: var(--active-green);
                }

                &.grey {
                    color: var(--grey-3);
                }

                &.yellow {
                    color: var(--yellow-1);
                }
            }

            &.withRequest {
                color: var(--yellow-1);
                font-size: 14px;
            }
        }

    }

    &--id {
        width: 10%;
        color: var(--grey-3)
    }

    &--name {
        width: 14%;
        color: var(--black-table);
        font-family: proximaSemibold;
    }

    &--calling {
        width: 12%;
        color: var(--grey-3)
    }

    &--phone {
        width: 12%;
        color: var(--grey-3)
    }

    &--park {
        width: 8%;

        &.purple {
            color: var(--purple);
        }

        &.pink {
            color: var(--pink);
        }
    }

    &--rate {
        width: 8%;
        color: var(--black-table);
    }

    &--cash {
        width: 7%;

        &.pink {
            color: var(--pink);
        }

        &.black {
            color: var(--black-table);
        }

    }

    &--auto {
        width: 18%;
        color: var(--grey-3)
    }

    &--button {
        width: 14%;
    }

}
