.orderAddress {
  display: flex;
  flex-direction: column;
  width: 48%;
  align-items: center;

  &.medium1 {
    width: 25%;
  }

  &.medium2 {
    width: 50%;
  }

  &.medium3 {
    width: 75%;
  }

  &.large {
    width: 100%;
  }

  .button {
    flex-shrink: 0;
    height: 3rem;
    margin-left: 1.5rem;
    margin-top: 0.5rem;
  }
}

.locationIcon {
  cursor: pointer;
  transition: all 0.2s ease;
  position: absolute;
  top: 27px;
  right: 32px;

  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

.addressInput {
  width: 100%;
  position: relative;
  margin-bottom: 16px;
  .clearButton{
    position: absolute;
    top: 32px;
    right: 7px;
  }
  >div{
    width: 100%;
    >input{
      height: 48px;
      border-radius:4px ;
      padding-right: 50px;
      padding-left: 8px;
      color: #000;
      font-family: proximaSemibold;
      font-size: 16px;
      border: 1px solid #000
    }
    >div{
      z-index: 99;
      >div{
        padding: 14px 8px;
        cursor: pointer;
        color: #000;
        font-size: 16px;
        font-family: proximaSemibold;
      }
    }
  }

  &--delete {
    margin-left: 0.5rem;
    margin-top: 5px;
    transition: all 0.2s ease;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .iconInput {
    padding-left: 2px;

    & input {
      padding-left: 15px;
    }
  }
}

.addressItem {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.pageHeaderOrder {
  display: flex;
  gap: 1rem;
  align-items: center;
}
