.newUser--rights {
    margin-top: 24px;
    display: flex;

    &-title {
        font-family: proximaSemibold;
        font-size: 18px;

        &_span {
            font-family: proximaRegular;
            font-style: italic;
        }
    }

    &-item {
        width: 350px;

        &:not(:first-child) {
            margin-left: 24px;
        }
    }
}