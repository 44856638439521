.form {
    display: flex;
    flex: 1 1 0px;

    &--item {
        @include flexColumn;
        width: 100%;

        &:not(:first-child) {
            margin-left: 32px;
        }

    }
}

input {
    &:disabled {
        color: var(--grey-3);
    }
}