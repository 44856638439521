.pageHeading {
    font-size: 1.25rem;
    font-family: proximaSemibold;

    &.small {
        font-size: 1rem;
    }

    &.marginBottom {
        margin-bottom: 1.5rem;
    }

    &--aditionalInfo {
        color: var(--blue-link-text);
        text-decoration: underline;
        font-family: proximaRegular;
        font-size: 1.125rem;
        margin-left: 1.5rem;
    }
}
