@mixin activeIcon {
    border: 1px solid var(--sidebar-active-icon-border);
    border-radius: 0.25rem;
    background-color: var(--sidebar-active-icon);
}

.sidebarItem {
    transition: all .2s;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black);

    &:hover {
        opacity: .8;
        @include activeIcon;
    }

    &.active {
        @include activeIcon;

        &:hover {
            opacity: 1;
        }
    }

    @media (max-width: 1367px) {
        margin-bottom: 20px;
    }
}

.item-text {
    color: var(--white);
    margin-bottom: 1.25rem;
    margin-top: 0.25rem;
    cursor: default;
}
