.btn {
    width: 100%;
    height: 3.25rem;
    border-radius: 0.375rem;
    font-family: proximaBold;
    font-size: 1rem;
    border: none;
    transition: all 0.2s ease-out;
    cursor: pointer;
    outline: none;

    &.btn-blue {
        background: var(--blue-4);
        color: var(--white);

        &:hover {
            background: var(--blue-5);
        }

        &:active {
            background: var(--blue-4);
        }
    }

    &.btn-white {
        background-color: var(--white);
        color: var(--grey-5);
        border: 1px solid var(--grey-5);
        border-radius: 0.375rem;

        &:hover {
            background-color: var(--grey-2);
            border: 1px solid var(--grey-2);
        }

        &:active {
            background-color: var(--white);
            border: 1px solid var(--grey-5);
        }

        &-outline {
            border: none;
            background-color: var(--white);
            color: var(--grey-5);
            transition: all .2s ease;

            &:hover {
                background-color: var(--grey-2);
            }
        }
    }

    &.btn-red {
        background: var(--red-link-text);
        color: var(--white);

        &:hover {
            background: var(--error-red);
        }

        &:active {
            background: var(--red-link-text);
        }
    }
}
