.OrderMap {
  & .controlsWrapper {
    justify-content: space-between;
  }

  & .Tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    button {
      margin-left: 128px;
    }
  }
  & .Toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    & > * {
      display: flex;
      align-items: center;
     }
  }
  & .TriggerButton.ViewPortTrigger {
    height: 3rem;
  }
  & .TriggerButton.RefetchDriversTrigger {
    height: 3rem;
    width: 3rem;
    border-radius: 0.25rem;
    border: 1px solid #cbcbcb;
    transition: border 0.2s ease;
    position: relative;
    overflow: hidden;

    &.RefetchDriversTrigger--loading {
      &::before {
        content: "";
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background-color: #399953;
        background-repeat: no-repeat;
        background-size: 50% 50%, 50% 50%;
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        background-image: linear-gradient(#399953, #399953),
          linear-gradient(#000, #000), linear-gradient(#399953, #399953),
          linear-gradient(#000, #000);
        animation: rotate 4s linear infinite;
      }

      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 1px;
        top: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background: white;
        border-radius: 2px;
      }
    }

    &:active {
      border: 1px solid black;
    }
  }
  & .TriggerButton.ViewCarsTrigger {
    border-radius: 0.25rem;
    height: 3rem;
    align-items: center;
    justify-content: center;

    &[data-state="true"] {
      border: 1px solid #ffdddd;
      background-color: #ffa6a6;
    }
    &[data-state="false"] {
      border: 1px solid #cbcbcb;
      background-color: white;
    }
    & > img {
      padding: 0.125rem;
    }
  }

  .navPanel {
    width: calc(50.5% + 30px);

    @media screen and (max-width: 1680px) {
      width: calc(50.5% + 20px);
    }

    @media screen and (max-width: 1600px) {
      width: calc(50% + 20px);
    }

    @media screen and (max-width: 1500px) {
      width: calc(50% + 10px);
    }
  }

  &--collapsed .navPanel {
    width: calc(100% - var(--sidebar-width));
  }

  &--filters .navPanel {
    padding-top: 0.65rem;
  }

  .additionalControls {
    display: flex;
    position: relative;
    top: 33px;

    .filters {
      margin: 0;
      height: 100%;
    }
  }
}

.MarkerWrapper--Ticket {
  // https://github.com/google-map-react/google-map-react/issues/956
  position: absolute;
  transform: translateZ(0) translate(-15%, -84%);
}

.MarkerWrapper--Car {
  position: absolute;
  transform: translateZ(0) translate(-43%, -100%);
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.RefetchDriversTrigger {
  border: 1px solid var(--blue-4);
  color: var(--blue-4);
  border-radius: 0.375rem !important;
  padding: 0.875rem 1rem !important;
  font-size: 1rem !important;
  height: 3rem !important;
  background-color: var(--white) !important;
  transition: all 0.2s ease;
  font-family: proximaRegular, sans-serif;
  gap: 0.5rem;
  flex-direction: row-reverse;

  .ant-btn-icon {
    margin: 0 !important;
  }

  & span:not(.ant-btn-icon) {
    vertical-align: middle;
    line-height: 1.15;
  }

  &:hover {
    background-color: var(--blue-4) !important;
    color: var(--white) !important;
  }
}
.OrderMap.page__map{
padding-top: 6.5rem;
}
