.sectionUnits {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.unitsSelect {
  max-width: 25rem;
}

.noUnit {
  text-align: center;
  font-size: 1.5rem;
  font-family: proximaBold, sans-serif;

}
