.marker-label {
    font-size: 12px !important;
    color: var(--white) !important;
    font-family: proximaBold !important;
    position: absolute;
    transform: translateX(-50%);

    &.orders_new_order {
        display: none;
    }

    &.driver_online,
    &.driver_offline,
    &.driver_onOrder {
        top: 25px;
    }

    &.order_accepted,
    &.order_not_accepted,
    &.order_not_accepted_2 {
        top: 5px;

        &:before {
            content: '#'
        }
    }
}