.navPanel {
    background-color: var(--white);
    position: fixed;
    top: 0;
    left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    flex-wrap: wrap;
    background-color: var(--white);
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    &.isDriverPage {
        height: unset;
        min-height: 3.625rem;
        padding: 0.375rem var(--content-padding-x);
    }

    &.medium {
      height: var(--navPanel-heigth);
    }

    &.small {
        min-height: 5rem;
        height: auto;
    }

    @media (max-width: 1441px) {
        height: var(--navPanel-heigth-adaptive);
    }
}
