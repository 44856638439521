.chat {
    &--container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 45%;
        height: 90%;
        background-color: var(--white);
        box-shadow: 0px 4px 39px rgba(162, 168, 218, 0.25);
        margin: 29px auto 0 auto;

        &-aside {
            position: absolute;
            right: -300px;
        }
    }
}