@import '../../../styles/assets/mixins';

.formInput {
    @include flexColumn;
    width: 100%;

    &.formInput {
        &-isPassword {
            flex-direction: row;
            align-items: center;
            gap: 5px;
        }
    }

    &-password__btn {
        position: relative;
        top: -1rem;
        padding: 5px 0.625rem;
        border: none;
        border-radius: 0.375rem;
        color: var(--white);
        background-color: var(--blue-4);
        cursor: pointer;
        &:hover {
            background-color: var(--blue-5);
        }
    }

    span {
      display: none;
      &[class] {
        display: inline;
        @include inputErrorSpan;
      }
    }

    label {
        display: block;
        font-size: 0.75rem;
        color: #000;
        font-weight: bold;
    }

    input {
        @include baseInput;

        &:invalid[blurred="true"],
        &.error {
            border: 1px solid var(--error-red);

            &:focus {
                outline: none;
            }
        }

        &:invalid[blurred="true"]~span {
            color: var(--error-red);
        }

        &:focus {
            outline: 2px solid var(--blue-4);
        }

        &.yellow {
            border: 1px solid var(--yellow-1);
        }

    }
}
