.authFormView {
  width: 500px;
  height: 415px;
  border-radius: 0.375rem;
  position: absolute;
  top: 60%;
  left: var(--login-right-margin);
  transform: translate(0, -50%);
  background-color: var(--white);
  padding: 55px 36px;

  &.big {
    height: 470px;
  }
}
