@import "../../../../styles/assets/mixins";

.checkbox-container {
    border: 1px solid var(--black);
    border-radius: 0.375rem;
    width: 100%;
    display: flex;
    position: relative;
    padding: 11px 40px 11px 72px;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.fitContent {
        width: fit-content;
    }

    @media (max-width: 1885px) {
        font-size: 1rem;
    }

    @media (max-width: 1810px) {
        font-size: 15px;
    }

    @media (max-width: 1770px) {
        padding: 11px 30px 11px 65px;
    }

    @media (max-width: 1653px) {
        padding: 11px 30px 11px 55px;
    }

    @media (max-width: 1586px) {
        padding: 11px 10px 11px 45px;
    }

    @media (max-width: 1440px) {
        padding: 11px 10px 11px 45px;
    }

    @media (max-width: 1370px) {
        padding: 11px 10px 11px 35px;
    }

    /* hide default checkbox */
    & input {
        @include hideInput;

        &:checked~.checkmark {
            @include checkedCheckboxAfter;
        }
    }

    &:hover {
        border-color: var(--green-1);

        & input~.checkmark {
            @include checkedCheckbox();
        }
    }

    & .checkmark:after {
        @include checkmarkAfter;
    }
}

.checkmark {
    @include checkmarkMixin(25px);

    @media (max-width: 1586px) {
        left: 15px;
    }

    @media (max-width: 1370px) {
        left: 0.625rem;
    }
}

.checkboxIcon {
    position: absolute;
    right: 0.625rem;
    display: flex;
    align-items: center;

    &--icon {
        margin-left: 5px;
    }
}
