.chartItem {
    height: 170px;
    width: 339px;
    border-radius: 0.75rem;
    background-color: var(--white);
    position: relative;

    &--info {
        width: 100%;
        height: 100px;
        padding: 23px 30px;

        &-value {
            color: var(--chart-black);
            font-size: 28px;
            font-family: proximaSemibold;
            margin-right: 5px;
        }

        &-tendency {
            font-size: 14px;
            font-family: proximaSemibold;

            &.green {
                color: var(--chart-green);
            }

            &.red {
                color: var(--chart-red);
            }
        }

        &-name {
            font-size: 18px;
            color: var(--chart-grey);
            margin-top: 0.625rem;
        }
    }

    &--chart {
        width: 100%;
        height: 70px;
        border-bottom: 5px solid var(--chart);
        border-radius: 0.75rem;
        //needed to avoid the bug with recharts lib resize on screen width change.
        //dont have any idea why is this works, but it does, so let it be.
        position: absolute;
    }

    &:not(:first-child) {
        margin-left: 40px;
    }
}
