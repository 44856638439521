.chat--messages {
    margin: 48px 14px 31px 14px;
    padding: 0 38px;
    width: 95%;
    height: 100%;
    overflow-y: scroll;
}

.messages--date {
    color: var(--grey-6);
    text-align: center;
    margin-bottom: 15px;
}

.message--item {
    width: 302px;
    margin-bottom: 17px;
    border-radius: 20px;
    padding: 24px 24px 30px 24px;
    word-break: break-word;
    position: relative;

    &.withFile {
        width: 302px;
        height: 311px;
    }

    &-author {
        font-family: proximaBold;
        margin-bottom: 2px;
    }

    &.inbox {
        background-color: var(--grey-7);
    }

    &.outbox {
        margin-left: auto;
        background-color: var(--grey-8);
    }
}