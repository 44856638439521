.controlsWrapper {
    display: flex;
    align-items: center;
    position: relative;
    height: 50px;

    &.marginTop {
        margin-top: 1rem;
    }

    &--buttons {
        display: flex;
        position: absolute;
        right: 0;
        &.isDriverPage {
            position: static;
            & > button {
                padding: 8px 1rem;
            }
        }
        button {
            margin-left: 24px;
        }
    }
}
