.switchWrapper {
    background-color: var(--white);
    border: 1px solid var(--grey-2);
    border-radius: 0.375rem;
    width: 21.875rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    padding: 0.25rem;
    margin-right: 1rem;

    &.big {
        width: 30rem;
    }
}

.switchItem {
    cursor: pointer;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10.5rem;
    border-radius: 0.375rem;
    transition: all .2s ease;

    &.active {
        background-color: var(--blue-link-background);
        color: var(--blue-link-text);
        cursor: default;
    }

    &:hover {
        color: var(--blue-link-text);
    }
}
