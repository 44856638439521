:root {
    --error-red: #ff0000;
    --black: #000;
    --white: #fff;
    --blue-1: #F1F6FA;
    --blue-4: #438AFE;
    --blue-5: #166ffd;
    --blue-link-text: #0081FF;
    --blue-link-background: #EEF8FF;
    --blue-link-background-2: #e6f4fd;
    --red-link-text: #FF433E;
    --red-1: #C12B2B;
    --red-link-background: #FFF0EF;
    --red-link-background-2: #ffe3e1;
    --grey-10: #7D7D7D;
    --grey-9: #F8F8F8;
    --grey-8: #F5F5F5;
    --grey-7: #E6E6E6;
    --grey-6: #C2C2C2;
    --grey-5: #6A707E;
    --grey-4: #ACACAC;
    --grey-3: #969BA0;
    --grey-2: #EBEBEB;
    --grey-1: #FDFDFD;
    --green-1: #41A628;
    --green-2: #45C86A;
    --login-right-margin: 53%;
    --sidebar-width: 100px;
    --sidebar-active-icon: #262626;
    --sidebar-active-icon-border: rgba(0, 129, 255, 0.2);

    --navPanel-heigth: 60px;
    --navPanel-heigth-adaptive: 60px;
    --content-padding-y: 30px;
    --content-padding-x: 15px;
    --select-option-height: 45px;
    --table-row-height: 5rem;
    --table-row-height-adaptive: 44px;
    --table-header-text: #656565;
    --active-green: #2BC155;
    --yellow-1: #FFB800;
    --yellow-2: #FFFDF8;
    --purple: #1A2AB8;
    --pink: #EA4989;
    --black-table: #242424;
    --grey-th-small-text: #A0ADBB;
    --red-row-bg: #FFF8F8;
    --green-row-bg: #f7fff5;
    --red-row-border: #FF2E00;
    --toastify-color-info: var(--blue-4);
    --toastify-color-error: var(--red-link-text);
    --inner-content-padding: 1.5rem;
    --black-2: #0B0B0B;
    --chart: #E5EEF9;
    --chart-black: #0F0F0F;
    --chart-green: #209F84;
    --chart-red: #D22626;
    --chart-grey: #3E4954;
}
