.filters {
    background-color: var(--white);
    height: 320px;
    border-radius: 0.375rem;
    margin-top: 15px;
    padding: 40px 32px;
    transition: all .2s ease;
    z-index: 0;

    &.enter {
        opacity: .1;
        transform: translateY((-5%));
    }

    &.enter-active {
        opacity: .2;
    }

    &.enter-done {
        opacity: 1;
    }

    &.exit {
        opacity: 0;
        transform: translateY((-5%));
    }

    &.exit-active {
        opacity: .1;
    }

    &.exit-done {
        opacity: 0;
    }

    &--title {
        font-family: proximaSemibold;
        font-size: 1rem;
    }

    &--items {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;

        &-row {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }
    }
}
