.slick-slider {
    //width: calc(233px * 5);
    width: calc(100% - 64px);
    margin: 0 32px 50px 32px;

    //@media (max-width: 1535px) {
    //    width: calc(186px * 5);
    //}
}

.slick-arrow {
    transition: all .2s ease;

    &:hover {
        &:not(.slick-disabled) {
            opacity: .5;
        }

        &.slick-disabled {
            cursor: default;
        }
    }

    &.slick-next {
        &::before {
            content: url(../../../../../img/icons/carousel/carousel_next_active.svg);
        }
    }

    &.slick-prev {
        &::before {
            content: url(../../../../../img/icons/carousel/carousel_prev_active.svg);
        }
    }
}

.slick-slide {
    width: max-content;
    min-width: 217px !important;
    height: 46px;
    background-color: var(--grey-8);
    border-radius: 4px;
    margin-left: 1rem;
    transition: all .2s ease;

    @media (max-width: 1535px) {
        width: max-content;
        min-width: 170px !important;
    }
}

.slideItem {
    width: max-content;
    min-width: 217px !important;
    height: 46px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 16px !important;
    word-break: break-all;
    transition: all .2s ease;

    &.active {
        border: 1px solid var(--blue-4);
        color: var(--blue-4);
    }

    &:hover:not(.active) {
        cursor: pointer;
        opacity: .9;
        color: var(--blue-4);
        transform: scale(1.1);
    }

    @media (max-width: 1535px) {
        width: max-content;
        min-width: 170px !important;
    }
}
.slick-track{
    display: flex;
}
