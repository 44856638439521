.helpdesk {
    &--status {
        &.green {
            color: var(--active-green);
        }

        &.yellow {
            color: var(--yellow-1);
        }
    }

    &--user,
    &--name {
        font-family: proximaSemibold;
        color: var(--black);
    }

    &--message {
        color: var(--active-green);
        text-align: left;
        padding: 0;
    }
}