.userRoute {
    background-color: var(--grey-9);
    width: 30%;
    height: 100%;
    padding: 56px 16px 16px 1rem;

    &--header {
        font-family: proximaSemibold;
        color: var(--blue-link-text);
        margin-bottom: 0.5rem;
    }

    &--clientName {
        font-family: proximaSemibold;
        font-size: 18px;
        color: var(--black-table);
        margin-bottom: 30px;
    }

    &--routeSteps {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }

    &--controls {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &--routeStep {
        font-size: 18px;
        display: flex;

        &:last-child {
            .userRoute--routeStep-control_icon {
                background-color: var(--black-2);
                color: var(--white);
            }

            .userRoute--routeStep-control_dots {
                display: none;
            }
        }

        &-control {

            &_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                font-family: proximaSemibold;
                width: 20px;
                height: 20px;
                border: 1px solid var(--black-2);
                border-radius: 100%;
                margin-right: 18px;
            }

            &_dots {

                height: 44px;
                width: 44px;
                border: none;
                transform: rotate(90deg) translateY(33px);

                &::before {
                    content: " ";
                    height: 2px;
                    display: block;
                    background: repeating-linear-gradient(to right,
                            rgba(125, 125, 125, 0.5) 0,
                            rgba(125, 125, 125, 0.5) 7px,
                            rgba(125, 125, 125, 0) 7px,
                            rgba(125, 125, 125, 0) 10px);
                }
            }
        }
    }
}
