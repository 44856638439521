.backButton {
    font-family: proximaSemibold;
    display: flex;
    cursor: pointer;
    transition: all .2s;
    width: 6.25rem;

    &:hover {
        opacity: .7;
    }

    &:active {
        opacity: 1;
    }
}

.backText {
    margin-left: 1rem;
    font-size: 1rem;
}
