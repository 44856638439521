.driversApplications {
    &--status {
        width: 11.65%;

        &.green {
            color: var(--active-green);
        }

        &.yellow {
            color: var(--yellow-1);
        }

        &.red {
            color: var(--red-link-text);
        }
    }

    &--id {
        width: 10%;
        color: var(--grey-3);
    }

    &--calling {
        width: 10%;
        color: var(--grey-3);
    }
    &--name {
        width: 15%;
        font-family: proximaSemibold;
        color: var(--black-table);
    }

    &--phone {
        width: 12%;
        color: var(--grey-3);
    }

    &--email {
        width: 16%;
        color: var(--grey-3);
    }

}
