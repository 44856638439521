.innerWrapper {
    width: 100%;
    height: 77vh;
    border-radius: 0.375rem;
    background-color: var(--white);
    padding: var(--inner-content-padding);
    margin-top: 1rem;
    overflow: hidden;
    overflow-y: scroll;

    &.withoutPadding {
        padding: 0;
    }

    &.withoutScroll {
        overflow-y: hidden;
    }

    &.isDriverPage {
        margin-top: 0;
        height: 93vh;
        padding: 0 0.625rem;
        background-color: unset;
    }

    @media (max-width: 1441px) {
        height: 66vh;
    }

    @media (max-width: 1367px) {
        height: 58vh;
    }
}
