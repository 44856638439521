.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 26rem;
  min-width: 26rem;
  min-height: 26rem;
  z-index: 2;
  padding: 1rem;
}

.info {
  text-align: center;
  color: var(--grey-3);
  margin-bottom: 40% !important;
}

.controls {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  margin-block: 1rem;
  align-items: center;
}

.unitsControls {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}

.unitsSelect {
  min-width: 20rem;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.mapControls {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 1rem;
  background-color: var(--white);
  padding: 1.5rem;
  border-top-left-radius: 1rem;
}

.mapWrapper {
  width: 100%;
}

.drawerControls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.map {
  position: relative;
  height: 80vh;
  width: 100%;
}

.button {
  border: 1px solid var(--grey-2);
  background-color: var(--white);
  color: var(--black);
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 0.375rem;
}

.active {
  border: 1px solid var(--green-1);
  background-color: var(--green-1);
}

.modal {
  max-width: 25rem;
  :global {
    .ant-modal-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
}

.modalTitle {
  text-align: center;
}

:global {
  .contentWrapper {
    padding-top: 5.5rem;
  }
}
