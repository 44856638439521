.serviceItem {
    display: flex;

    & button {
        margin-bottom: 25px;
        margin-left: 1rem;
    }

    & .formInput {
        &:not(:first-child) {
            margin-left: 0.625rem;
        }
    }
}
