@import "../../../../styles/assets/mixins";

.customDate {
    width: 100%;
    margin-right: 1.125rem;
    z-index: 2;

    &--input {
        @include baseInput;
        width: 100%;
        outline: none;
        margin: 0;
        height: 3rem;
        color: var(--black);
        font-size: 1rem;
        font-family: proximaRegular, sans-serif;
        font-weight: bold;
        cursor: pointer;
        background-image: url(../../../../img/icons/inputs/date_input_icon.svg);
        background-repeat: no-repeat;
        background-position: 95%;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: var(--blue-4);
    }
}
