.chatForm {
    padding: 0 40px 24px 40px;

    button {
        margin-top: 18px;
    }

    &-addPhoto {
        display: flex;
        color: var(--blue-link-text);

        &:hover {
            cursor: pointer;
            color: var(--blue-4);

            .chatForm-addPhoto--label {
                text-decoration: none;
            }
        }

        &--label {
            margin-right: 0.625rem;
            margin-left: 5px;
            text-decoration: underline;
            transition: all .2s ease;

            &:hover {
                cursor: pointer;
            }
        }

        &--icon {
            transition: all .2s ease;
        }

    }
}
