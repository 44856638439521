.orderCost {
    &--item {
        width: 100%;
        flex-shrink: 0;
        max-width: 17.375rem;
    }
}

.paymentAppointment {
    display: flex;
    gap: 3rem;

    & .orderAppointment {
      width: 50%;
    }
}
