.infoVehicle {
    margin-top: 24px;
    &--container {
        margin-bottom: 0.625rem;
        padding: 0.625rem;
        border-radius: 0.375rem;
        background-color: var(--white);
    }
    &--property {
        padding: 10px 0 10px 0;
        display: flex;

        &-item {
            width: 220px;

            &:not(:first-child) {
                margin-left: 24px;
            }
        }

    }

    //&--form {
    //    padding-top: 0.625rem;
    //}

    &--techSertificate,
    &--vehiclePhotos {
        margin-top: 37px;

        & .divider {
            margin-top: 55px;
        }

        &-inputs {
            display: flex;
            margin-top: 32px;
        }
    }
    &--techSertificate {
        &.isDriverPage {
            margin-top: 0;
            & .infoVehicle--techSertificate-inputs {
                margin-top: 15px;
            }
        }
    }


}
