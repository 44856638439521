.DriversTableWrap {
  table tbody tr{
    height: 2.5rem;
  }
  table td{
    border-top: none;
  }
  .button.link--blue {
    padding: 4px 24px;
  }
  .tableWrapper .scrollableBody{
    height: 90vh;
  }
  .navPanel{
    justify-content: flex-start;
  }
  .controlsWrapper{
    margin-left: 16px;
  }
  .switchWrapper{
    margin-left: 16px;
  }
  .pagination {
    margin-left: auto;
    margin-top: 10px;
  }
  .tableSearchPanel{
    height: 2.5rem;
    .tableSearch .tableSearchInput input{
      height: 2.5rem;
    }
  }
}