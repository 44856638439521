.icon-filter {
    border: 1px solid var(--blue-4);

    &.icon-filter-1 {
        width: 1.125rem;
    }

    &.icon-filter-2 {
        width: 0.7rem;
    }

    &.icon-filter-3 {
        width: 0.4rem;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.125rem;
    }
}
