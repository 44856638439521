@mixin checkedCheckbox {
    background-color: var(--blue-4);
    border-color: var(--blue-4);
}

.checkbox-container-login {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 2px;
    margin-bottom: 0.75rem;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* hide default checkbox */
    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.checkmark-login:after {
            display: block;
        }

        &:checked~.checkmark-login {
            @include checkedCheckbox();
        }
    }

    &:hover input~.checkmark-login {
        @include checkedCheckbox();
    }

    & .checkmark-login:after {
        left: 0.375rem;
        top: 1px;
        width: 5px;
        height: 0.625rem;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.checkmark-login {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: var(--white);
    border: 1px solid var(--black);
    transition: all .2s ease;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}
