.clientCard--list-item {
    width: 356px;
    border-bottom: 1px solid var(--grey-2);
    padding-bottom: 1rem;
    padding-left: 44px;
    position: relative;

    &:not(:first-child) {
        margin-top: 13px;
    }

    &_icon {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-25%);
    }

    &_card {
        font-size: 17px;
    }

    &_number {
        margin-top: 0.625rem;
        font-size: 17px;
    }

    &_delete {
        width: fit-content;
        color: var(--red-link-text);
        text-decoration: underline;
        font-size: 18px;
        transition: all .2s ease;
        margin-left: auto;
        margin-top: 5px;
        user-select: none;
        border: none;
        background-color: var(--white);
        display: block;

        &:hover {
            cursor: pointer;
            color: var(--red-1);
            text-decoration: none;
        }

        &:active {
            color: var(--red-link-text);
        }

        &:disabled {
            color: var(--grey-3);

            &:hover {
                cursor: default;
                text-decoration: underline;
            }
        }
    }
}
