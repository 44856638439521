@mixin onHover {
    cursor: pointer;
    opacity: .8;
}

@mixin tabsButton {
    height: 3.25rem;
    width: 3.25rem;
    border: 1px solid var(--grey-2);
    border-radius: 0.375rem;
    background-color: var(--grey-1);
    background-repeat: no-repeat;
    background-position: 50%;
    transition: all .2s ease;
}

.ordersMap {
    &--tab {
        padding: 1rem 2rem;
        border: 1px solid var(--grey-2);
        border-radius: 0.375rem;
        background-color: var(--grey-1);
        color: var(--grey-3);
        transition: all .2s ease;
        cursor: default;

        &.active {
            border: 1px solid var(--green-1);
            color: var(--green-1);
        }

        &:hover:not(.active) {
            @include onHover();
            border: 1px solid var(--green-1)
        }

        &:not(:first-child) {
            margin-left: 1rem;
        }
    }
    &--contentWrapper {
        display: flex;
        width: 100%;
        height: 105%;
        flex-direction: column;

        .table-wrapper {
          width: 55%;
        }

        &.collapsed {
          flex-direction: row;
        }
    }
    &--map {
        height: 100vh;
        width: 100%;
        margin-right: -10px;
        margin-top: calc(-30px - var(--navPanel-heigth));

        &.wide {
          margin-right: 0;
          height: 85%;
          margin-top: 0;
        }
    }
    &--table {
        width: 96%;
        height: 100%;

        & .table-container.collapsed {
          height: 55%;
          margin-top: 28%;
       }
    }
    &--close {
        @include tabsButton();
        background-image: url(../../../img/icons/cross.svg);

        &:hover {
            @include onHover();
            border: 1px solid var(--grey-3);
        }
    }
}
