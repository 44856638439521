@mixin flexColumn {
    display: flex;
    flex-direction: column;
}
@mixin inputErrorSpan {
    font-size: 0.75rem;
    padding: 3px;
    color: var(--white);
    user-select: none;
    margin-bottom: 7px;
    min-height: 18px;

    &.error {
        color: var(--error-red);
    }
}
@mixin baseInput {
    padding: 0.8rem;
    margin: 0.625rem 0 0.25rem 0;
    border: 1px solid var(--black);
    border-radius: 0.375rem;
    background-color: var(--grey-1);
    font-size: 1.125rem;
}
@mixin checkmarkMixin($left) {
    position: absolute;
    top: 50%;
    left: $left;
    transform: translateY(-50%);
    height: 1.2rem;
    width: 1.2rem;
    background-color: var(--white);
    border: 2px solid var(--grey-3);
    border-radius: 100%;
    transition: all .2s ease;


    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}
@mixin checkedCheckbox {
    background-color: var(--white);
    border-color: var(--green-1);
}
@mixin checkmarkAfter {
    width: 0.8125rem;
    height: 0.8125rem;
    background-color: var(--green-1);
    border-radius: 100%;
    top: 1px;
    left: 1px;
}
@mixin checkedCheckboxAfter {
    @include checkedCheckbox();

    &::after {
        display: block;
    }
}
@mixin hideInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

@mixin setFont( $font: proximaRegular, $size: 14px, $color: black ) {
    font-family: $font ;
    font-size: $size ;
    color: $color ;
}
