.contentWrapper {
    height: 100vh;
    background-color: var(--blue-1);
    padding-left: calc(var(--sidebar-width) + var(--content-padding-x));
    padding-right: var(--content-padding-x);
    padding-top: calc(var(--navPanel-heigth) + var(--content-padding-y));
    overflow: hidden;
    &.isOverflowAuto{
        overflow: auto;
    }
    &.smallPaddingTop {
        padding-top: calc(var(--navPanel-heigth) + 1.25rem);
    }
    &.isDriverPage {
        padding-top: 70px;
    }

    @media (max-width: 1367px) {
        padding-left: calc(var(--sidebar-width) + var(--content-padding-x) - 15px);
        padding-right: var(--content-padding-x);
        padding-top: calc(var(--navPanel-heigth) + var(--content-padding-y) - 30px);
    }
}
