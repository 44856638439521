.infoDocuments {
    display: flex;
    //margin-top: 24px;
    //margin-bottom: 56px;
    gap: 50px;
    flex-wrap: wrap;
    &-container {
        display: flex;
        margin-bottom: 0.625rem;
        padding: 0.625rem;
        border-radius: 0.375rem;
        background-color: var(--white);
    }

    & .driverLicense {
        display: flex;
        margin-top: 15px;
    }

    & .insurancePolicy {
        //margin-top: 61px;

        &--content {
            display: flex;
            //margin-bottom: 56px;
            margin-top: 15px;
        }
    }
}
