.allClients {
    &--check {
        width: 5%;
    }
    &--status {
        width: 8%;

        &.green {
            color: var(--active-green);
        }

        &.yellow {
            color: var(--yellow-1);
        }

        &.red {
            color: var(--red-link-text);
        }
    }
    &--name {
        width: 9%;
    }
    &--phone {
        width: 9%;
        color: var(--grey-3);
    }
    &--card {
        width: 9%;
        color: var(--grey-3);
    }
    &--balance {
        width: 5%;
        color: var(--grey-3);
    }

    &--date {
        width: 10%;
        color: var(--grey-3);
    }
    &--order {
        width: 10%;
        color: var(--grey-3);
    }
    &--op {
        width: 9%;
        color: var(--grey-3);
    }

    &--buttons {
        width: 9%;

        & div.wrapper {
            display: flex;
            justify-content: center;

            & button {
                margin-left: 32px;
            }
        }
    }

}
.ClientsPage {
  .navPanel{
    justify-content: flex-start;
      align-items: center;
 }
}

.totalClients {
    margin-left: 1.5rem;
    font-size: 1.25rem;
}
.clientsActionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .allClients--phone{
        width: 100%;
    }
}
.clientsPhoneSearch {
    max-width: 166px;
    width: 100%;
    background: #fff;
    margin-right: 16px;
    border: 1px solid var(--black);
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    padding-right: 10px;
    .tableSearch{
        width: 100%;
    }
    input:focus::-webkit-input-placeholder {
        color: transparent;
    }

}
.search__row{
    display: flex;
    width: 100%;
    button:not(.searchButton) {
        margin-right: 16px;
        margin-left: 18px;
    }
}
.allClientTable {
    table tbody tr{
        height: 3rem;
    }
    table td{
       border-top: none;
    }
    .button.link--blue {
        padding: 8px 32px;
    }
    .pagination {
        margin-left: auto;
    }
    .tableWrapper .scrollableBody{
        height: 90vh;
    }
}
.lock_icon{
    cursor: pointer;
    display: none;
    &:hover{
        opacity: 0.8;
    }
    &.disabled{
        pointer-events: none;
        opacity: 0.5;
    }

}
