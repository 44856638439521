.text-link {
    color: var(--blue-link-text);
    text-decoration: underline;

    &:hover {
        color: var(--blue-5);
        text-decoration: none;
        cursor: pointer;
    }
}

.pac-container {
    border: 1px solid var(--grey-2);
    border-radius: 0 0 6px 0.375rem;
    width: 450px !important;
    z-index: 1031 !important;

    &.pac-logo {
        &::after {
            display: none;
        }
    }
}

.pac-icon {
    display: none;
}

.pac-item {
    height: var(--select-option-height);
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .2s ease;
    font-size: 1rem;

    & .radio {
        display: none;
    }
}

.pac-item:hover {
    cursor: pointer;
    background-color: rgba(24, 154, 180, 0.1);
}
.WithOutNav {
    padding-top: 16px;
    .navPanel{
        display: none;
    }
    .innerWrapper{
        height: 100%;
        overflow: auto;
        padding-bottom: 10px;
    }
}
.ant-select-selector,.ant-picker{
    border: 1px solid #000 !important;
}
::placeholder {
    color: #000;
    font-family: proximaSemibold;
}
