$driver-photo-size: 70px;

.personalInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    &--container {
        margin-bottom: 0.625rem;
        padding: 1rem;
        border-radius: 0.375rem;
        background-color: var(--white);
    }

    &--photo {
        display: flex;
        align-items: center;
        flex: 0 0 10%;
        &__wrap {
            margin-left: 1rem;
            text-align: center;
        }

        .driverId {
            display: block;
            font-size: 1rem;
            color: var(--grey-3);
            margin-top: 0.625rem;
        }

        img {
            height: $driver-photo-size;
            width: $driver-photo-size;
            border-radius: 100%;
        }

        & .preview {
            background-color: var(--grey-2);
            width: $driver-photo-size;
            height: $driver-photo-size;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &--square {
                width: 1.5rem;
                height: 1.25rem;
                border-radius: 0.25rem;
                background-color: var(--grey-4);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--circle {
                width: 0.625rem;
                height: 0.625rem;
                border-radius: 100%;
                background-color: var(--grey-2);
            }
        }

    }

    &--fileInput {
        &.fileInput-button {
            & > label {
                margin-top: 0;
                height: unset;
                padding: 0.625rem;
                font-size: 1rem;
            }
        }
    }

    &--form {
        display: flex;
        flex: 1;
        gap: 0.625rem;
        flex-wrap: wrap;
        & > .formInput {
            flex: 0 0 0;
            & input {
                padding: 0.625rem;
                font-size: 0.875rem;
            }
        }

        & .formInput-password__btn {
          top: -3px;
          align-self: flex-end;
        }
    }

    &--balance {
        flex: 0 0 10%;
        min-width: 168px;
        height: 107px;
        //margin-left: 30%;
        border: 1px solid var(--blue-link-text);
        border-radius: 0.375rem;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 0.5rem;

        & span {
            &.text {
                font-size: 0.75rem;
                color: var(--blue-link-text);
            }

            &.amount {
                color: var(--black);
                font-size: 1.5rem;
                font-family: proximaSemibold;
                margin: 3px 0;
            }
        }

        & button {
            font-size: 14px;
            padding: 6px 5px !important;
        }
    }
}
