.additionalServices {
    position: relative;
    gap: 1rem;
    flex-wrap: wrap;
    display: flex;
  flex-direction: column;

    .checkbox-container  {
      margin: 0;
      padding: 0.65rem 2.5rem;
    }

    .checkmark {
      left: 0.75rem;

      &::after {
        width: 0.82rem;
        height: 0.82rem;
      }
    }
}

.ant-select-selector {
  height: 2.5rem !important;

  .ant-select-selection-item {
    line-height: 2.5rem !important;
  }
}
