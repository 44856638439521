@mixin plus {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
}

.icon-add {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid var(--blue-4);
    border-radius: 0.25rem;
    position: relative;

    &::before {
        @include plus();
        width: 0.625rem;
        margin-left: -0.3125rem;
        margin-top: -1px;
        border-top: 2px solid;
    }

    &::after {
        @include plus();
        height: 0.625rem;
        margin-left: -1px;
        margin-top: -0.34rem;
        border-left: 2px solid;
    }
}
