.director--section {
  width: 21.1875rem;
  height: 18.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-family: proximaBold, sans-serif;
  transition: all 0.2s ease;
  user-select: none;
  text-decoration: none;
  color: var(--table-black);
  margin-top: 3rem;
  margin-left: 3rem;
  background-size: cover;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  &.blue {
    background-image: url(../../../img/background/director_tariffs.png);
  }

  &.green {
    background-image: url(../../../img/background/director_users.png);
  }

  &.purple {
    background-image: url(../../../img/background/director_analytics.png);
  }
}
