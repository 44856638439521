.sortingButton {
    margin-left: 1rem;
    width: 0.5rem;
    height: 1rem;

    &.desc {
        background: url(../../../../img/icons/sorting/arrow_down.svg)
    }

    &.asc {
        background: url(../../../../img/icons/sorting/arrow_up.svg)
    }
}
