.clientInfo--form {
    padding-bottom: 0;

    &.padding {
        padding: var(--inner-content-padding);
    }

    form {
        width: 70%;
        margin-bottom: 30px;
        button {
            max-width: 214px;
        }
        fieldset {
            display: flex;
            width: 100%;
        }
    }
}