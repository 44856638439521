.filtersButton-wrapper {
    margin-left: auto;
}

.dateWrapper {
    width: 280px;
    margin-right: 20px;

    & .react-datepicker-wrapper {
      width: 100%;
    }
}

.createNewOrderPage {
  padding-top: 1rem;
  .innerWrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;

    .formInput input {
      padding: 0.875rem !important;
    }
  }
}
.OrdersTableWrap {
  .filtersButtons{
    display: flex;
    gap: 16px;
  }
  table tbody tr{
    height: 2.5rem;
  }
  table td{
    border-top: none;
  }
  .button.link--blue {
    padding: 4px 24px;
  }
  .table-container.small{
    height: 90vh;
  }
  .navPanel{
    justify-content: flex-start;
    width: 100%;
  }
  .controlsWrapper{
    width: calc(100% - 116px);
  }
  .select-container{
    margin-left: 16px;
  }
  .switchWrapper{
    margin-left: 16px;
  }
  .select-box{
    width: 200px;
  }
  .dateWrapper{
    width: 200px;
  }
  .pagination {
    margin-left: auto;
    margin-top: 0;
    height: 20px;
  }
  .tableSearchPanel{
    height: 2rem;
    .tableSearch .tableSearchInput input{
      height: 2rem;
    }
  }
  .ant-statistic{
    display: flex;
    align-items: center;
    &-title{
      margin-bottom: 0;
      margin-right: 4px;
      color: #0B0B0B;
    }
  }
}
.OrderMultiSelect{
  width: 100%;
  max-width: 200px;
  height: 39px;
  margin-right: 16px;
  .searchWrapper{
    border: 1px solid #000;
    min-height: 39px;
  }
  .highlightOption{
    background-color: #fff;
    color: #000;
  }
  .selected{
    border: none;
    &:after{
      display: none;
    }

  }
}
