.pagination {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    user-select: none;

    &.noMargin {
        margin-top: 0px;
    }

    @media (max-width: 1441px) {
        margin-top: 20px;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 100%;
        color: var(--blue-link-text);
        padding: 0 3px;
        transition: all .1s ease;
        font-size: 20px;
        cursor: pointer;

        &:hover {
            opacity: .5;
        }

        &.current {
            color: var(--grey-3);
            cursor: default;

            &:hover {
                opacity: 1;
            }
        }

        &--dots {
            color: var(--grey-3);
            padding: 0 3px;
            cursor: default;
        }

        &.disabled {
            cursor: default;

            &:hover {
                opacity: 1;
            }
        }
    }
}