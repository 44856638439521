.infoTariffs {
    display: flex;
    margin-top: 24px;
    &--container {
        margin-bottom: 0.625rem;
        padding: 0.625rem;
        border-radius: 0.375rem;
        background-color: var(--white);
    }

    &--tariffs-col {
        display: flex;
        gap: 0.625rem;
        flex-wrap: wrap;
        & > label {
            width: 250px;
            padding: 11px 35px;
            font-size: 14px;
            & > span {
                left: 0.5rem;
            }
        }
        //width: 350px;

        //&:not(:first-child) {
        //    margin-left: 34px;
        //}
    }
}
