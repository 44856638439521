@import "../../../../styles/assets/mixins";

.iconInput {
  display: flex;
  border: 1px solid var( --black);
  border-radius: 0.375rem;
  padding: 0 0.75rem;
  margin-top: 0.5rem;

  &:focus-within {
    outline: 2px solid var(--blue-4);
  }

  &.error {
    border: 1px solid var(--error-red);
  }

  &--label {
    font-size: 0.75rem;
    color: var( --black);
    font-weight: bold;
  }

  &--input {
    width: 100%;
    height: 100%;

    input {
      width: 95%;
      height: 3rem;
      font-size: 1.125rem;
      outline: none;
      border: none;
    }
  }
}

.clearButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
  transition: transform .2s ease;

  &:hover {
    transform: scale(1.1)
  }
}

.iconInput--errorMessage {
  @include inputErrorSpan;
}
