.createOrderModal {

    &--input {
        margin: 24px 16px 0px 1rem;
        width: 30%;
    }

    &--wrapper {
        height: 500px;
    }

    &--map {
        margin: 0 1rem;
        height: 100%;
    }

    &--buttons {
        width: 40%;
        margin: 12px 16px 24px 1rem;

        button {
            margin-top: 0.625rem;
        }
    }

}
