.selectTime {
    margin: 1rem 0;

    &--item {
        width: 50%;
        gap: 1rem;
    }

    .ant-picker-input input {
      font-size: 1rem;
    }

    .ant-picker {
      padding: 0.65rem !important;
    }
}
