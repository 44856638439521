.spinner {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: 3px solid var(--grey-2);
    border-top-color: var(--blue-4);
    animation: spinner .7s linear infinite;

    &.regular {
        height: 60px;
        width: 60px;
        margin-top: -30px;
        margin-left: -30px;
    }

    &.small {
        height: 30px;
        width: 30px;
        margin-top: -15px;
        margin-left: -15px;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}