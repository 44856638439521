.fileInput-button {
    &--file {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
    }

    & label {
        display: block;
        position: relative;
        padding: 14px 20px 12px 20px;
        background-color: var(--blue-link-background);
        color: var(--blue-link-text);
        text-decoration: underline;
        border-radius: 0.5rem;
        transition: all .2s ease;
        font-size: 18px;
        margin-top: 36px;
        min-width: 150px;
        height: 46px;
        user-select: none;

        &:hover {
            background-color: var(--blue-link-background-2);
            color: var(--blue-5);
            text-decoration: none;
            cursor: pointer;
        }
    }
}
