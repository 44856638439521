.clientInfo {
  display: flex;
  gap: 1rem;
  margin-left: 15px;
  label{
    display: block;
    font-size: 1.25rem;
    color: #116062;
    font-weight: bold;
  }
  .orderTariffWrapper {
    width: 20%;
    flex-shrink: 0;

    .selected {
      padding: 1rem;
    }
  }

  .selectorAntd {
    min-width: 15rem;
    margin: 0.625rem 0 0.25rem 0;


    .ant-select-arrow {
      transition: transform 0.5s ease;
    }

    &.ant-select-open .ant-select-arrow {
      transform: rotateX(180deg);
    }
    .ant-select-selector {
      border: 1px solid var(--black) !important;
      font-family: proximaRegular, sans-serif;
      font-weight: normal;
      border-radius: 0.375rem;
      color: var(--grey-3);
      padding-block: 0.375rem !important;
      height: 3.1rem !important;
      box-shadow: none !important;
    }

    .ant-select-selection-search {
      padding-top: 0.375rem;
    }

    .ant-select-selection-placeholder {
      color: var(--grey-3);
      font-weight: 400;
      line-height: 2.4rem !important;
    }

    .ant-select-arrow img {
      width: 0.75rem;
      height: 0.375rem;
    }
  }
}
