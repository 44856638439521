.table-container {
    overflow: auto;
    position: relative;
    margin-top: 18px;
    height: 80%;

    .tableSearchPanel {
        td {
            padding-left: 20px;
        }
    }

    &.size {
      &-small {
        --table-row-height: 60px;

        .tableHeaderWrapper {
          font-size: 0.625rem;
        }

        td,
        th {
            font-size: 0.75rem;
            padding-left: 0.625rem;
            max-width: 120px !important;
            min-width: fit-content !important;
        }
      }
    }

    td,
    th {
        &:not(:first-child) {
            max-width: 300px;
            min-width: 200px;
        }
    }

    &.small {
        height: 70%;

        &.collapsed {
            height: 26%;
        }
    }

    &.collapsed {
        height: 35%;
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 1;
        margin-top: -20px;
        background-color: var(--blue-1);
    }
}
