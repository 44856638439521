.tableSearch {
    display: flex;

    button.searchButton {
        background-color: var(--white);
        border: none;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }

    .tableSearchInput {
        width: 100%;
        height: 100%;

        input {
            width: 95%;
            height: 40px;
            font-size: 14px;
            outline: none;
            border: none;
            margin-left: 0.625rem;
            color: var(--grey-3);
        }
    }
}
