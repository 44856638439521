td {
    color: var(--black-table);
    font-family: proximaSemibold;

    &.black {
        color: var(--black-table);

        &-driver,
        &-client {
            color: var(--black-table);
            font-family: proximaSemibold;
        }
    }
    &.card {
      color: var(--chart-red);
      font-weight: bolder;
    }

    &.approved span {
      display: flex;
      align-items: center;
      min-height: 50px;
      width: 100%;
      justify-content: center;
      background-color: rgb(26, 42, 184, 0.2);
    }

    &.orders-cells-driver {
      text-align: center;
    }
}

.statusText {
    &.red {
        color: var(--red-link-text);
    }

    &.yellow {
        color: var(--yellow-1);
    }

    &.grey {
        color: var(--grey-3);
    }
}

.ordersItem--controls {

    &.enter {
        opacity: .1;
        transform: translateY((-10%));
    }

    &.enter-active {
        opacity: .2;
    }

    &.enter-done {
        opacity: 1;
    }

    &.exit {
        opacity: 0;
        transform: translateY((-10%));
    }

    &.exit-active {
        opacity: .1;
    }

    &.exit-done {
        opacity: 0;
    }

    &:hover {
        cursor: default;
    }

    &-buttons {
        margin-top: 5px;
        max-height: 39px;
        display: flex;

        button {
            min-width: 200px;
            &[data-disabled="true"] {
                opacity: .5 ;
                pointer-events: none ;
            }

            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }

    &-mapRoute {
        height: 37px;
        min-width: 53px;
        border: 1px solid var(--blue-link-text);
        border-radius: 0.375rem;
        background-color: var(--white);
        background-image: url(../../../img/icons/map/route.svg);
        background-repeat: no-repeat;
        background-position: 50%;
        transition: all .2s ease;
        margin-left: 1rem;

        &[data-enabled="false"] {
            opacity: .5 ;
            pointer-events: none ;
            background-image: url(../../../img/icons/map/route_white.svg);
            background-color: var(--grey-5);
        }
        &[data-enabled="true"] {
            &:hover {
                background-image: url(../../../img/icons/map/route_white.svg);
                background-color: var(--blue-4);
                cursor: pointer;
            }
        }
    }

    td {
        background-color: var(--blue-1);
        overflow: visible;
        vertical-align: top;

        &:before {
            content: "";
            border-bottom: none;
            border-top: none;
            position: absolute;
            pointer-events: none;
        }

        &:first-child {
            border-radius: 0;

            &:before {
                content: "";
                border-left: none;
                position: absolute;
                border-radius: 6px 0 0 0.375rem;
                pointer-events: none;
            }
        }

        &:last-child {
            border-radius: 0;

            &:before {
                content: "";
                border-right: none;
                position: absolute;
                border-radius: 0 6px 6px 0;
                pointer-events: none;
            }
        }
    }
}
