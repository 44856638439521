@import '../../../../styles/assets/mixins';

@mixin setHeader {
    border-radius: 4px ;
    white-space: nowrap ;
    font-family: proximaRegular;
    font-size: 14px ;
    color: white ;
    padding: .5em ;
    text-align: center;
}

.TicketMarker {
    $size: 53px ;

    display: flex ;
    flex-direction: column ;
    align-items: center ;
    justify-content: flex-end ;
    cursor: pointer;
    filter: drop-shadow( 0 2px 2px rgb( 0 0 0 / .25 ) );

    &> .Header {
        @include setHeader() ;
        padding: 0.3rem;
        margin-bottom: 1em;
        border-radius: 99em;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            width: 1rem;
            height: 1rem;
            left: 0.5rem;
            bottom: -4px;
            transform: rotate(45deg);
            background-color: #FFB800;
            z-index: -1;
        }

        .MarkerHourly {
          margin-left: 0.5rem;
        }
    }
    &> .MarkerIcon {
        width: $size ;
        height: $size ;
    }
    &> .MarkerHourly {
        position: absolute;
        top: 3.5em;
        right: -2.5em;
    }
    &[data-active="false"]:hover {
        z-index: 9999 ;
    }
    &[data-active="true"] {
        &> .Header { opacity: 0 }
    }
}
.CarMarker {
    $width: 50px ;
    $height: 50px ;

    display: flex ;
    flex-direction: column ;
    align-items: center ;
    justify-content: flex-end ;
    cursor: pointer;
    filter: drop-shadow( 0 2px 2px rgb( 0 0 0 / .25 ) );

    &> .Header {
        @include setHeader() ;
        margin-bottom: 0;
        background-color: slateblue !important;
        border-radius: 99em;
        margin-bottom: -8px;
    }

    &> .CarMarkerIcon {
        width: $width ;
        height: $height ;
    }
    &> .MarkerHourly {
        position: absolute;
        top: 3.5em;
        right: -2.5em;
    }
    &[data-active="false"]:hover {
        z-index: 9999 ;
    }
    &[data-active="true"] {
        &> .Header { opacity: 0 }
    }
}
.MarkerPath {
    $size: 1.5em ;
    width: $size ;
    height: $size ;
    margin-top: - calc( ($size / 2)) ;
    border-radius: 100% ;
    font-family: proximaBold;
    font-size: 14px ;
    background-color: red ;
    color: white ;
    padding-top: 2px;
    text-align: center;
}
.Information {
    $width: 440px ;
    $colorDefault: rgba( 255, 255, 255, .65 ) ;
    $colorHover: white ;

    filter: drop-shadow( 0 2px 2px rgb( 0 0 0 / .25 ) );
    position: absolute;
    bottom: 5.5em;
    right: -2.3em;
    cursor: auto ;
    z-index: 9999 ;
    display: flex ;
    flex-direction: column ;
    width: $width ;
    padding: 1em ;
    border-radius: 6px ;
    background-color: $colorDefault ;

    &:hover {
        z-index: 9999 ;
        background-color: $colorHover ;
        &> .Arrow { color: $colorHover }
    }

    &> .Close {
        display: flex ;
        align-items: center ;
        justify-content: center ;
        cursor: pointer ;
        position: absolute;
        top: 1em ;
        right: 1em ;
        width: 20px;
        height: 20px ;
        padding: .5em ;
        border-radius: 4px ;
        border: 1px solid #EBEBEB ;
        background-color: white ;
        &> span {
            color: #6A707E ;
        }
    }
    &> .Arrow {
        transform: rotate(45deg);
        position: absolute;
        right: 1em ;
        bottom: -12.5px ;
        font-family: cursive;
        font-size: 1.5em;
        color: $colorDefault ;
    }
    &> .Column {
        display: flex ;
        width: 100% ;
        flex-direction: row ;
        justify-content: space-between;
        padding-bottom: 1.5em ;

        & .Cell {
            display: flex;
            flex-direction: column ;
            &> div:first-child {
                @include setFont( proximaRegular, 14px, #969BA0 ) ;
                width: 100% ;
                text-transform: uppercase ;
                padding-bottom: .5em ;
            }
        }
        & .TimeLabel {
            display: flex;
            flex-direction: row ;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &> div:first-child {
                @include setFont( proximaRegular, 14px ) ;
                padding-right: .5em ;
            }
            &> div:last-child {
                @include setFont( proximaRegular, 14px, #45C86A ) ;
            }
        }


        &> .Cell.W50 { width: calc( 50% - .5em ) }
        &> .Cell.W100 { width: 100% }

        & [ data-cell ] {
            @include setFont( proximaRegular, 14px, black ) ;
        }
        & [ data-cell="id" ] {
            text-align: center ;
            background-color: #45C86A ;
            border-radius: 2px ;
            padding: 4px 12px ;
            color: white; ;
        }
        & [ data-cell="status" ] {
            text-align: center ;
            border-radius: 2px ;
            padding: 4px 12px ;
            color: white; ;
        }
        & [ data-cell="addresses" ] {
            &> .Address {
                display: flex ;
                flex-direction: row ;
                justify-content: flex-start ;
                align-items: center ;
                padding-bottom: .5em ;
                &> .Index {
                    @include setFont( proximaRegular, 14px, black ) ;
                    $size: 20px ;
                    padding-top: 1px ;
                    width: $size ;
                    height: $size ;
                    border-radius: 100% ;
                    background-color: white ;
                    border: 1px solid black ;
                    text-align: center;
                    margin-right: .5em ;
                }
            }
        }
    }
}
.CarInformation {
    $width: 300px ;
    $colorDefault: rgba( 255, 255, 255, .65 ) ;
    $colorHover: white ;

    filter: drop-shadow( 0 2px 2px rgb( 0 0 0 / .25 ) );
    position: absolute;
    bottom: 5.5em;
    right: -2.3em;
    cursor: auto ;
    z-index: 9999 ;
    display: flex ;
    flex-direction: column ;
    width: $width ;
    padding: 1em ;
    border-radius: 6px ;
    background-color: $colorDefault ;

    &:hover {
        z-index: 9999 ;
        background-color: $colorHover ;
        &> .Arrow { color: $colorHover }
    }

    &> .Close {
        display: flex ;
        align-items: center ;
        justify-content: center ;
        cursor: pointer ;
        position: absolute;
        top: 1em ;
        right: 1em ;
        width: 20px;
        height: 20px ;
        padding: .5em ;
        border-radius: 4px ;
        border: 1px solid #EBEBEB ;
        background-color: white ;
        &> span {
            color: #6A707E ;
        }
    }
    &> .Arrow {
        transform: rotate(45deg);
        position: absolute;
        right: 1em ;
        bottom: -12.5px ;
        font-family: cursive;
        font-size: 1.5em;
        color: $colorDefault ;
    }
    &> .Column {
        display: flex ;
        width: 100% ;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 1.5em ;

        & .Cell {

            & .title {
              color: var(--grey-10);
              font-size: 0.75rem;
              margin-right: 0.5rem;
            }
            & .value {
              @include setFont( proximaBold, 14px ) ;
              font-weight: 800;
            }

            &> div:first-child {
                @include setFont( proximaRegular, 14px, #969BA0 ) ;
                width: 100% ;
                text-transform: uppercase ;
                padding-bottom: .5em ;
            }
        }
        & .TimeLabel {
            display: flex;
            flex-direction: row ;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &> div:first-child {
                @include setFont( proximaRegular, 14px ) ;
                padding-right: .5em ;
            }
            &> div:last-child {
                @include setFont( proximaRegular, 14px, #45C86A ) ;
            }
        }
        &> .Cell.W50 { width: calc( 50% - .5em ) }
        &> .Cell.W100 { width: 85% }

        & [ data-cell ] {
            @include setFont( proximaRegular, 14px, black ) ;
        }
        // & [ data-cell="id" ] {
        //     text-align: center ;
        //     background-color: #45C86A ;
        //     border-radius: 2px ;
        //     padding: 4px 12px ;
        //     color: white; ;
        // }
        // & [ data-cell="speed" ] {
        //     color: #45C86A;
        // }
        // & [ data-cell="downtime" ] {
        //     color: #FFB800;
        // }
    }
}
