@mixin coloredButton($color1, $color2) {
    border: 1px solid $color1;
    color: $color1;

    &:hover {
        background-color: $color1;
        color: var(--white);
    }

    &:active {
        background-color: $color2;
        border: 1px solid $color2;
    }

    &.background-filled {
        background-color: $color1;
        color: var(--white);

        & div {
            border: 1px solid var(--white);
        }
    }
}

@mixin linkButton($color1, $color2, $color3, $color4) {
    background-color: $color1;
    color: $color2;
    text-decoration: underline;
    border: none;
    padding: 1rem 2rem;

    &:hover {
        background-color: $color3;
        color: $color4;
        text-decoration: none;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    padding: 0.875rem 1rem;
    font-size: 1rem;
    gap: 1rem;
    background-color: var(--white);
    transition: all .2s ease;
    cursor: pointer;
    font-family: proximaRegular;
    min-width: 7.8rem;
    outline: none;

    &.fullWidth {
        width: 100%
    }

    &.blue {
        @include coloredButton(var(--blue-4), var(--blue-5));
    }

    &.green {
        @include coloredButton(var(--green-1), var(--green-1));
    }

    &.orders {
        @include coloredButton(var(--blue-4), var(--blue-5));
        color: var(--black);
        font-size: 0.875rem;
        padding: 0.625rem 1.5rem;
    }

    &.transparent {
        background-color: rgba($color: #fff, $alpha: 0);
    }

    &.link--blue {
        @include linkButton(var(--blue-link-background), var(--blue-link-text), var(--blue-link-background-2), var(--blue-5));
    }

    &.link--red {
        @include linkButton(var(--red-link-background), var(--red-link-text), var(--red-link-background-2), var(--error-red));
    }

    &:hover div {
        border: 1px solid var(--white);
    }

    &:focus {
        outline: 2px solid var(--blue-4);
    }
}
