@import "../../../styles/assets/mixins";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--sidebar-width);
    min-height: 100%;
    overflow-y: auto;
    background-color: var(--black);
    padding-top: var(--content-padding-y);
    text-align: center;

    &__icons {
        margin-top: 94px;
        @include flexColumn;
        align-items: center;


        @media (max-width: 1367px) {
            margin-top: 30px;
        }
    }

    & div.logout {
        position: absolute;
        bottom: 40px;
        right: 50%;
        transform: translateX(50%);
        transition: all .2s ease;
        user-select: none;

        &:hover {
            opacity: .8;
            cursor: pointer;
        }
    }
}