.teleteTariff {
    &-buttons {
        display: flex;
        align-items: center;
        height: 48px;
        margin-top: 58px;

        button {
            height: 48px;
            width: 167px;

            &:not(:first-child) {
                margin-left: 1rem;
                margin-top: 0px;
            }
        }
    }
}
