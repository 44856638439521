.smallLink {
    font-size: 14px;
    font-family: proximaRegular;
    color: var(--blue-4);
    cursor: pointer;
    transition: all .2s;

    &:hover {
        color: var(--blue-5);
    }

    &:active {
        color: var(--blue-4);
    }
}